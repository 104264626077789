/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable object-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */

import {
  useCallback, useMemo, useState,
} from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import Loading from '@/components/common/Icons/svg/Loading';
import Logo from '@/components/common/Icons/svg/Logo';
import { useStore } from '@/utils/store';
import { Button } from '@/components/common/Button';
import { Tooltip } from '@/components/common/Dropdwon';
import reRouteTo from '@/utils/api/sessionPass';
import useQRcode from '@/components/common/Icons/useQRcode';
import ServicePlatformChoice from '@/components/common/ServicePlatformChoice/ServicePlatformChoice';
import usePortal from '@/components/common/Modal/Portal';
import { getPlatform } from '@/utils/functions/common';
import { usePoolInfo } from '@/utils/hooks/quizPool/usePoolListData';
import useH5StringBuilder from '@/utils/hooks/useH5StringBuilder';
import ProfileIcon from './icons/ProfileIcon';
import DownLoadIcon from './icons/DownLoadIcon';
import LanguageIcon from './icons/LanguageIcon';
import MarketsIcon from './icons/MarketsIcon';
import TradeIcon from './icons/TradeIcon';
import BuyCryptoIcon from './icons/BuyCrypto';
// import CopyTradeIcon from './icons/CopyTrade';
import SupportIcon from './icons/Support';
import QuizPoolIcon from './icons/QuizPool';
import MoreIcon from './icons/More';
import BitopIcon from './icons/Bitop';
import Hamburger from './icons/Hamburger';
import UnAuthIcon from './icons/UnAuthIcon';
import AdvanceAuthIcon from './icons/AdvanceAuthIcon';
import CrossIcon from './icons/CrossIcon';
import LogoutIcon from './icons/Logout';
import OldVersionIcon from './icons/oldVersion';
import PhoneIcon from './icons/PhoneIcon';
import styles from './MainHeader.module.scss';

const userNameCover = (userName) => {
  if (typeof userName !== 'string') return userName;
  const visableCharNum = Math.trunc(userName.length / 4);
  return userName
    .split('')
    .fill('*', visableCharNum, userName.length - visableCharNum)
    .join('');
};

const routeFunction = ({
  route,
  router,
  user,
  t,
  i18n,
  phrase,
  referral,
}) => {
  let langUrl = '';
  if (i18n.language === 'en') {
    langUrl = '/en';
  }

  switch (route) {
    case 'login':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        reRouteTo('/login');
      } else {
        reRouteTo(`${phrase}/login/login${referral}`);
      }
      break;

    case 'register':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        reRouteTo('/register');
      } else {
        reRouteTo(`${phrase}/login/reg${referral}`);
      }
      break;

    case 'markets':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        router.push('/markets');
      } else {
        router.push('/markets');
      }
      break;

    case 'spot':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        if (user) {
          reRouteTo('/trade/spot/BTCUSDT_CC');
        } else {
          router.push(`${langUrl}/trade/spotV2/BTCUSDT_CC`);
        }
      } else {
        reRouteTo(`${phrase}/spotQuote/BTCUSDT_CC${referral}`);
      }
      break;

    case 'margin':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        if (user) {
          reRouteTo('/trade/deal/BTCUSDT');
        } else {
          router.push('/trade/margin/BTCUSDT');
        }
      } else {
        reRouteTo(`${phrase}/trade/BTCUSDT${referral}`);
      }
      break;

    case 'bitcast':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        if (user) {
          reRouteTo('/trade/game/BTCUSDT');
        } else {
          router.push('/trade/bitcast/BTCUSDT');
        }
      } else {
        reRouteTo(`${phrase}/bitcast/BTCUSDT${referral}`);
      }
      break;

    case 'thirdParty':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        router.push('/trade/bitcast/BTCUSDT');
      } else {
        reRouteTo(`${phrase}/deposit/0${referral}`);
      }
      break;

    case 'c2c':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        if (user) {
          reRouteTo('/c2c');
        } else {
          router.push('/download');
        }
      } else {
        reRouteTo(`${phrase}/deposit/0${referral}`);
      }
      break;

    case 'copyTrade':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        reRouteTo('/trade/follow');
      } else {
        reRouteTo(`${phrase}/myFollow${referral}`);
      }
      break;

    case 'announcement':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        router.push('/news/announcement');
      } else {
        router.push('/news/announcement');
      }
      break;

    case 'helpCenter':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        router.push('/helpCenter/beginnerGuide');
      } else {
        router.push('/helpCenter/beginnerGuide');
      }
      break;

    case 'legalCenter':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        router.push('/legalCenter/termsOfService');
      } else {
        router.push('/legalCenter/termsOfService');
      }
      break;

    case 'agent':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        reRouteTo('/trade/agent/agent');
      } else {
        reRouteTo(`${phrase}/inviteRebate${referral}`);
      }
      break;

    case 'exchange':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        reRouteTo('/trade/fastChange');
      } else {
        reRouteTo(`${phrase}/exchange${referral}`);
      }
      break;

    case 'news':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        router.push('/news/stories');
      } else {
        router.push('/news/stories');
      }
      break;

    case 'eventCenter':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        router.push('/eventCenter');
      } else {
        reRouteTo(`${phrase}/eventCenter${referral}`);
      }
      break;

    case 'quizPool': {
      const now = new Date(); // date now
      const start = 1713801600000; // 2024-04-23 00:00:00
      const distance = start - now.getTime();
      const tempHours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const tempMinutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const tempSeconds = Math.floor((distance % (1000 * 60)) / 1000);
      const countDownErr = t('nav.quizPool.countDownErr', {
        h: tempHours,
        m: tempMinutes,
        s: tempSeconds,
      });

      if (start < now) {
        if (getPlatform() === 'pad' || getPlatform() === 'pc') {
          router.push('/quizPool');
        } else {
          router.push('/quizPool');
        }
      } else {
        toast.error(countDownErr, { id: 'countDownErr' });
      }
      break;
    }

    case 'download':
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        router.push('/download');
      } else {
        router.push('/download');
      }
      break;

    default:
      if (getPlatform() === 'pad' || getPlatform() === 'pc') {
        reRouteTo('/trade/deal/BTCUSDT');
      } else {
        reRouteTo(`${phrase}${referral}`);
      }
  }
};

function Mobile({
  router,
  t,
  i18n,
  setLanguage,
  user,
  logout,
}) {
  const [hamburg, setHamburg] = useState(false);
  const { phrase, referral } = useH5StringBuilder();

  const handleLocaleChange = useCallback(
    async (locale) => {
      await setLanguage(locale);
      router.replace(router.asPath, router.asPath, { locale });
    },
    [router],
  );

  let langUrl = '';
  if (i18n.language === 'en') {
    langUrl = '/en';
  }

  return (
    <div className={styles.mobile}>
      <div className={styles.nav}>
        <Link href={`${langUrl}/`}>
          <figure>
            <BitopIcon />
          </figure>
        </Link>
      </div>

      <section className={styles.functionality}>
        {!user && (
          <div
            className={styles.login}
            onClick={() => routeFunction({ route: 'login', router, i18n, user, phrase, referral })}
            onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'login', router, i18n, user, phrase, referral }); }}
            role="button"
            tabIndex={0}
          >
            {t('login/register')}
          </div>
        )}
        <div
          onClick={() => setHamburg(true)}
          onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') setHamburg(true); }}
          role="button"
          tabIndex={0}
          aria-label="Menu"
        >
          <Hamburger />
        </div>
      </section>
      <div className={classnames({ [styles.open]: hamburg }, styles.overlay)}>
        <div className={styles.hamburgerMenu}>
          <div
            className={styles.cross}
            onClick={() => setHamburg(false)}
            onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') setHamburg(false); }}
            role="button"
            tabIndex={0}
          >
            <CrossIcon />
          </div>
          {!user && (
            <div className={styles.btns}>
              <div
                className={styles.login}
                role="button"
                onClick={() => routeFunction({ route: 'login', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'login', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('login')}
              </div>
              <div
                className={styles.register}
                role="button"
                onClick={() => routeFunction({ route: 'register', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'register', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('register')}
              </div>
            </div>
          )}

          <div className={classnames(styles.routeCon, { [styles.loggedIn]: user, phrase, referral })}>
            <div
              className={styles.route}
              role="button"
              onClick={() => routeFunction({ route: 'markets', router, i18n, user, phrase, referral })}
              onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'markets', router, i18n, user, phrase, referral }); }}
              tabIndex={0}
            >
              <MarketsIcon />
              {t('nav.markets.title')}
            </div>

            <details>
              <summary className={styles.route}>
                <TradeIcon />
                <div>{t('nav.trade.title')}</div>
              </summary>
              <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'spot', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'spot', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.trade.dropDown.main1')}
              </div>
              <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'margin', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'margin', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.trade.dropDown.main3')}
              </div>
              {/* <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'bitcast', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'bitcast', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.trade.dropDown.main4')}
              </div> */}
              <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'copyTrade', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'copyTrade', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.copyTrading.title')}
              </div>
            </details>

            <details>
              <summary className={styles.route}>
                <BuyCryptoIcon />
                <div>{t('nav.buyCrypto.title')}</div>
              </summary>
              <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'c2c', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'c2c', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.buyCrypto.dropDown.main1')}
              </div>
            </details>

            {/* <div
              className={styles.route}
              role="button"
              onClick={() => routeFunction({ route: 'copyTrade', router, i18n, user, phrase, referral })}
              onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'copyTrade', router, i18n, user, phrase, referral }); }}
              tabIndex={0}
            >
              <CopyTradeIcon />
              {t('nav.copyTrading.title')}
            </div> */}

            <details>
              <summary className={styles.route}>
                <SupportIcon />
                <div>{t('nav.support.title')}</div>
              </summary>
              <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'announcement', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'announcement', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.support.dropDown.main1')}
              </div>
              <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'helpCenter', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'helpCenter', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.support.dropDown.main2')}
              </div>
              <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'legalCenter', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'legalCenter', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.support.dropDown.main3')}
              </div>
            </details>

            <details>
              <summary className={styles.route}>
                <MoreIcon />
                <div>{t('nav.more.title')}</div>
              </summary>
              <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'agent', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'agent', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.more.dropDown.main1')}
              </div>
              <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'exchange', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'exchange', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.more.dropDown.main2')}
              </div>
              {/* <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction('news')}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction('news'); }}
                tabIndex={0}
              >
                {t('nav.more.dropDown.main3')}
              </div> */}
              <div
                className={styles.subroute}
                role="button"
                onClick={() => routeFunction({ route: 'eventCenter', router, i18n, user, phrase, referral })}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'eventCenter', router, i18n, user, phrase, referral }); }}
                tabIndex={0}
              >
                {t('nav.more.dropDown.main4')}
              </div>
            </details>

            <div
              className={styles.route}
              role="button"
              onClick={() => routeFunction({ route: 'quizPool', router, t, i18n, user, phrase, referral })}
              onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'quizPool', router, t, i18n, user, phrase, referral }); }}
              tabIndex={0}
            >
              <QuizPoolIcon />
              {t('nav.quizPool.title')}
              <div className={styles.tips}>
                {t('nav.quizPool.tips')}
              </div>
            </div>

            <div
              className={styles.route}
              role="button"
              onClick={() => routeFunction({ route: 'download', router, i18n, user, phrase, referral })}
              onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: 'download', router, i18n, user, phrase, referral }); }}
              tabIndex={0}
            >
              <DownLoadIcon />
              {t('download.title')}
            </div>

            <details>
              <summary className={styles.route}>
                <LanguageIcon />
                <div>{t('language.title')}</div>
              </summary>
              <div
                className={styles.subroute}
                role="button"
                onClick={() => { handleLocaleChange('zh'); setHamburg(false); }}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') { handleLocaleChange('zh'); setHamburg(false); } }}
                tabIndex={0}
              >
                简体中文
              </div>
              <div
                className={styles.subroute}
                role="button"
                onClick={() => { handleLocaleChange('en'); setHamburg(false); }}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') { handleLocaleChange('en'); setHamburg(false); } }}
                tabIndex={0}
              >
                English
              </div>
            </details>

            <div
              className={styles.route}
              role="button"
              onClick={() => routeFunction({ route: '', router, i18n, user, phrase, referral })}
              onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') routeFunction({ route: '', router, i18n, user, phrase, referral }); }}
              tabIndex={0}
            >
              <OldVersionIcon />
              {t('loginPopup.oldVersionBtn')}
            </div>

            {user && (
              <div
                className={styles.route}
                role="button"
                onClick={() => logout()}
                onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') logout(); }}
                tabIndex={0}
              >
                <LogoutIcon />
                {t('user.logout')}
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
}

function MainHeader() {
  const router = useRouter();
  const { user, kyc, logout } = useStore().auth;
  const {
    storageData, setLanguage,
  } = useStore().setting;
  const { t, i18n } = useTranslation('common', { keyPrefix: 'newHeader' });
  const { qrCode } = useQRcode({ locale: i18n.language, ru: storageData?.ru });
  const { Portal, PortalOpen, PortalClose } = usePortal({ closeOnOutSide: true });
  // pool info
  const { poolInfo } = usePoolInfo();

  const handleRedirectTo = useCallback((e) => {
    const { dataset } = e.currentTarget;
    if (dataset.path === 'toLogin') return PortalOpen();
    return reRouteTo(dataset.path);
  }, []);

  const handleLocaleChange = useCallback(
    async (locale) => {
      await setLanguage(locale);
      router.replace(router.asPath, router.asPath, { locale });
    },
    [router],
  );

  const kycLvl = kyc?.currentLevel || 0;

  const renderUserMenu = useMemo(() => {
    if (!user) return undefined;
    const { account, userId } = user;

    const kycTxt = [t('user.kycLevel.0'), t('user.kycLevel.0'), t('user.kycLevel.0'), t('user.kycLevel.1')][kycLvl];
    const kycTagLvl = [styles.unAuth, styles.unAuth, styles.unAuth, styles.advanceAuth][kycLvl];
    const KycIcon = [UnAuthIcon, UnAuthIcon, UnAuthIcon, AdvanceAuthIcon][kycLvl];

    return (
      <section className={styles.profile}>
        <header className={styles.account}>
          <div>
            <p>{userNameCover(account)}</p>
            <p className={styles.id}>{`ID : ${userId}`}</p>
          </div>
          <div className={classnames(styles.kycTag, kycTagLvl)}>
            <KycIcon />
            {kycTxt}
          </div>
        </header>
        <nav className={styles.profileNav}>
          <Button data-path="/trade/account/account" onClick={handleRedirectTo} variant="plane">
            <p>{t('user.overview')}</p>
          </Button>
          <Button data-path="/trade/account/secure" onClick={handleRedirectTo} variant="plane">
            <p>{t('user.security')}</p>
          </Button>
          <Button data-path="/trade/account/followManagement" onClick={handleRedirectTo} variant="plane">
            <p>{t('user.follow')}</p>
          </Button>
          <Button data-path="/trade/account/bringManagement" onClick={handleRedirectTo} variant="plane">
            <p>{t('user.lead')}</p>
          </Button>
          <Button onClick={logout} variant="plane">
            <p>{t('user.logout')}</p>
          </Button>
        </nav>
      </section>
    );
  }, [t, user, kycLvl, logout, handleRedirectTo]);

  const authSection = useMemo(() => {
    if (user === undefined) {
      return (
        <div className={styles.loading}>
          <Loading />
        </div>
      );
    }
    if (user === false) {
      return (
        <>
          <Button data-path="/login" onClick={handleRedirectTo} className={styles.login} variant="plane">
            {t('login')}
          </Button>

          <Button data-path="/register" onClick={handleRedirectTo} className={styles.register} variant="plane">
            {t('getStarted')}
          </Button>
        </>
      );
    }
    return (
      <>
        <div className={styles.btnCon}>
          <Tooltip
            className={styles.assetSelector}
            show="center"
            anchor={(
              <Button variant="empty">
                {t('asset.title')}
                {/* <span className={styles.triangle} /> */}
              </Button>
            )}
          >
            <Button data-path="/trade/asset/asset" variant="plane" onClick={handleRedirectTo}>
              <p>{t('asset.assetDetail')}</p>
            </Button>
            <Button data-path="/trade/asset/Recharge" variant="plane" onClick={handleRedirectTo}>
              <p>{t('asset.balanceDetail')}</p>
            </Button>
            <Button data-path="/trade/asset/Spotg" variant="plane" onClick={handleRedirectTo}>
              <p>{t('asset.orderDetail')}</p>
            </Button>
          </Tooltip>
        </div>
        <div className={styles.btnCon}>
          <Tooltip
            className={styles.historySelector}
            show="center"
            anchor={(
              <Button variant="empty">
                {t('order.title')}
                <span className={styles.triangle} />
              </Button>
            )}
          >
            <Button data-path="/trade/asset/Agreement" variant="plane" onClick={handleRedirectTo}>
              <p>{t('order.contract')}</p>
            </Button>
            <Button data-path="/trade/asset/Spotg" variant="plane" onClick={handleRedirectTo}>
              <p>{t('order.spot')}</p>
            </Button>
          </Tooltip>
        </div>
        <div className={styles.btnCon}>
          <Tooltip
            className={styles.profileSelector}
            show="center"
            anchor={(
              <figure>
                <ProfileIcon />
              </figure>
            )}
          >
            {renderUserMenu}
          </Tooltip>
        </div>
      </>
    );
  }, [t, user, handleRedirectTo, renderUserMenu]);

  const getEmptyHeader = (num) => {
    const emptyLoadingArray = [];
    for (let i = 0; i < num; i++) {
      emptyLoadingArray.push(
        <Button key={i}>
          <Loading />
        </Button>,
      );
    }
    return emptyLoadingArray;
  };

  let langUrl = '';
  if (i18n.language === 'en') {
    langUrl = '/en';
  }

  const quizPoolSetTime = (lang) => {
    const now = new Date(); // date now
    const start = 1713801600000; // 2024-04-23 00:00:00
    const distance = start - now.getTime();
    const tempHours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const tempMinutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const tempSeconds = Math.floor((distance % (1000 * 60)) / 1000);
    const countDownErr = t('nav.quizPool.countDownErr', {
      h: tempHours,
      m: tempMinutes,
      s: tempSeconds,
    });

    if (start < now) {
      router.push(`${lang}/quizPool`);
    } else {
      toast.error(countDownErr, { id: 'countDownErr' });
    }
  };

  function Desktop() {
    return (
      <div className={styles.desktop}>
        <div className={styles.nav}>
          <Link href={`${langUrl}/`}>
            <figure>
              <Logo />
            </figure>
          </Link>
          <nav className={styles.navLinks}>
            <div className={styles.btnCon}>
              <Link href={`${langUrl}/markets`}>
                <Button
                  variant="empty"
                  className={styles.navDropDown}
                >
                  {t('nav.markets.title')}
                </Button>
              </Link>
            </div>
            <div className={styles.btnCon}>
              <Tooltip
                className={styles.dropDownSelector}
                anchor={(
                  <Button variant="empty">
                    {t('nav.trade.title')}
                  </Button>
                )}
              >
                {
                  user === undefined ? (getEmptyHeader(3))
                    : (
                      <>
                        <Button
                          onClick={() => {
                            window.location.href = (user ? '/trade/spot/BTCUSDT_CC' : `${langUrl}/trade/spotV2/BTCUSDT_CC`);
                          }}
                          variant="plane"
                        >
                          <p>{t('nav.trade.dropDown.main1')}</p>
                          <p>{t('nav.trade.dropDown.desc1')}</p>
                        </Button>

                        {/* <Button
                          onClick={() => {
                            window.location.href = (user ? '/trade/perpetual/BTCUSDT' : `${langUrl}/trade/perpetualV2/BTCUSDT`);
                          }}
                          variant="plane"
                        >
                          <p>{t('nav.trade.dropDown.main2')}</p>
                          <p>{t('nav.trade.dropDown.desc2')}</p>
                        </Button> */}

                        <Button
                          onClick={() => {
                            window.location.href = (user ? '/trade/deal/BTCUSDT' : `${langUrl}/trade/margin/BTCUSDT`);
                          }}
                          variant="plane"
                        >
                          <p>{t('nav.trade.dropDown.main3')}</p>
                          <p>{t('nav.trade.dropDown.desc3')}</p>
                        </Button>
                        {/* <Button
                          onClick={() => {
                            window.location.href = (user ? '/trade/game/BTCUSDT' : `${langUrl}/trade/bitcast/BTCUSDT`);
                          }}
                          variant="plane"
                        >
                          <p>{t('nav.trade.dropDown.main4')}</p>
                          <p>{t('nav.trade.dropDown.desc4')}</p>
                        </Button> */}

                        <Button
                          data-path="/trade/follow"
                          onClick={handleRedirectTo}
                          variant="plane"
                          className={styles.navDropDown}
                        >
                          <p>{t('nav.copyTrading.title')}</p>
                          <p>{t('nav.copyTrading.desc')}</p>
                        </Button>
                      </>
                    )
                }
              </Tooltip>
            </div>

            <div className={styles.btnCon}>
              <Tooltip
                className={styles.dropDownSelector}
                anchor={(
                  <Button variant="empty">
                    {t('nav.buyCrypto.title')}
                  </Button>
                )}
              >
                {
                  user === undefined ? (getEmptyHeader(1))
                    : (
                      <Button
                        onClick={() => {
                          window.location.href = user ? '/c2c' : '/download';
                        }}
                        variant="plane"
                      >
                        <p>{t('nav.buyCrypto.dropDown.main1')}</p>
                        <p>{t('nav.buyCrypto.dropDown.desc1')}</p>
                      </Button>
                    )
                }
              </Tooltip>
            </div>
            {/* <div className={styles.btnCon}>
              <Button data-path="/trade/follow" onClick={handleRedirectTo} variant="empty" className={styles.navDropDown}>
                {t('nav.copyTrading.title')}
              </Button>
            </div> */}

            <div className={styles.btnCon}>
              <Tooltip
                className={styles.dropDownSelector}
                anchor={(
                  <Button variant="empty">
                    {t('nav.support.title')}
                  </Button>
                )}
              >
                {
                  user === undefined ? (getEmptyHeader(3))
                    : (
                      <>
                        <Button onClick={() => router.push(`${langUrl}/news/announcement`)} variant="plane">
                          <p>{t('nav.support.dropDown.main1')}</p>
                          <p>{t('nav.support.dropDown.desc1')}</p>
                        </Button>
                        <Button onClick={() => router.push(`${langUrl}/helpCenter/beginnerGuide`)} variant="plane">
                          <p>{t('nav.support.dropDown.main2')}</p>
                          <p>{t('nav.support.dropDown.desc2')}</p>
                        </Button>
                        <Button onClick={() => router.push(`${langUrl}/legalCenter/termsOfService`)} variant="plane">
                          <p>{t('nav.support.dropDown.main3')}</p>
                          <p>{t('nav.support.dropDown.desc3')}</p>
                        </Button>
                      </>
                    )
                }
              </Tooltip>
            </div>

            <div className={styles.btnCon}>
              <Tooltip
                className={styles.dropDownSelector}
                anchor={(
                  <Button variant="empty">
                    {t('nav.more.title')}
                  </Button>
                )}
              >
                {
                  user === undefined ? (getEmptyHeader(3))
                    : (
                      <>
                        <Button data-path={user ? '/trade/agent/agent' : 'toLogin'} onClick={handleRedirectTo} variant="plane">
                          <p>{t('nav.more.dropDown.main1')}</p>
                          <p>{t('nav.more.dropDown.desc1')}</p>
                        </Button>
                        <Button data-path="/trade/fastChange" onClick={handleRedirectTo} variant="plane">
                          <p>{t('nav.more.dropDown.main2')}</p>
                          <p>{t('nav.more.dropDown.desc2')}</p>
                        </Button>
                        <Button onClick={() => router.push(`${langUrl}/news/stories`)} variant="plane">
                          <p>{t('nav.more.dropDown.main3')}</p>
                          <p>{t('nav.more.dropDown.desc3')}</p>
                        </Button>
                        <Button onClick={() => router.push('/eventCenter')} variant="plane">
                          <p>{t('nav.more.dropDown.main4')}</p>
                          <p>{t('nav.more.dropDown.desc4')}</p>
                        </Button>
                      </>
                    )
                }
              </Tooltip>
            </div>

            <div className={styles.btnCon}>
              <Button
                variant="empty"
                className={styles.navDropDown}
                onClick={() => quizPoolSetTime(langUrl)}
              >
                {t('nav.quizPool.title')}
              </Button>
            </div>
            <Tooltip
              className={styles.quizPool}
              show="center"
              special="newQuizPool"
              anchor={(
                <div className={styles.tips}>
                  {t('nav.quizPool.tips')}
                </div>
              )}
            >
              <div className={styles.todayCP}>
                {t('nav.quizPool.dropDown.main')}
                <p>
                  <span>{ poolInfo ? poolInfo.poolPrize || 0 : 0 }</span>
                  {' '}
                  USDT
                </p>
              </div>
            </Tooltip>
          </nav>
        </div>

        <section className={styles.functionality}>
          <div className={styles.user}>{authSection}</div>
          <div className={styles.btnCon}>
            <Tooltip
              className={styles.downLoadInfo}
              show="right"
              anchor={(
                <figure>
                  <DownLoadIcon />
                </figure>
              )}
            >
              <div className={styles.downLoadDropDown}>
                <div className={styles.qrcode}>
                  <figure>
                    <Image src={qrCode} layout="fill" />
                  </figure>
                </div>
                <div className={styles.downLoadRight}>
                  <div className={styles.firstRow}>
                    <PhoneIcon />
                    <span>{t('download.device')}</span>
                  </div>
                  <div className={styles.secondRow}>
                    <span dangerouslySetInnerHTML={{ __html: t('download.details') }} />
                  </div>
                  <div
                    className={styles.thirdRow}
                    role="button"
                    onClick={() => router.push('/download')}
                    onKeyUp={(e) => { if (e.key === 'Enter' || e.key === 'Space') router.push('/download'); }}
                    tabIndex={0}
                  >
                    <p>{t('download.btn')}</p>
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>

          <div className={styles.btnCon}>
            <Tooltip
              className={styles.languageSelector}
              show="center"
              anchor={(
                <figure>
                  <LanguageIcon />
                </figure>
              )}
            >
              <Button variant="plane" selected={i18n.language === 'zh'} onClick={() => handleLocaleChange('zh')}>
                <p>简体中文</p>
              </Button>
              <Button variant="plane" selected={i18n.language === 'en'} onClick={() => handleLocaleChange('en')}>
                <p>English</p>
              </Button>
            </Tooltip>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div>
      <div className={styles.container}>
        <Desktop />
        <Mobile t={t} router={router} setLanguage={setLanguage} i18n={i18n} user={user} logout={logout} />
      </div>

      <div className={styles.portal}>
        <Portal>
          <ServicePlatformChoice cross closeFunc={PortalClose} width="10%" />
        </Portal>
      </div>
    </div>

  );
}

export default observer(MainHeader);
